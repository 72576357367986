import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { MenuItemGtmService } from '@core/services/google-tag-manager.service';
import * as fromProfile from '@core/store/reducers';
import { environment } from '@env/environment';
import { AddOnTaxServicesRouteSegment } from '@modules/custodian/add-on-tax-services/enums/add-on-tax-services-route-segment.enum';
import { CustodianRouteSegment } from '@modules/custodian/enums/custodian-route-segment.enum';
import { TaxRiskReportingRouteSegment } from '@modules/custodian/tax-risk-reporting/enums/tax-risk-reporting-route-segment.enum';
import { MessageService } from '@modules/messages/services/message.service';
import { select, Store } from '@ngrx/store';
import { roleLandingMap } from '@shared/constants/role-landing.map';
import { DesignFeature } from '@shared/enums/design-feature.enum';
import { Feature } from '@shared/enums/feature.enum';
import { AccountCompleteness, ReportType, UserRole } from '@wtax/data-angular';
import isNil from 'lodash/isNil';
import { combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { delay, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { RouteSegment } from '../../shared/enums/route-segment.enum';
import { isCurrentUserCustodian } from './header.selector';
import { MenuItemType } from './menu-item-type.enum';
import { MenuItem } from './menu-item.interface';
import { NavigationItemBadge } from './navigation-item-badge.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
  private readonly dashboardLink = [RouteSegment.Root, RouteSegment.Dashboard];
  private readonly clientManagementLink = [RouteSegment.Root, RouteSegment.ClientManagement];
  private readonly fundManagementLink = [RouteSegment.Root, RouteSegment.FundManagement];
  private readonly yourTasksLink = [RouteSegment.Root, RouteSegment.YourTasks];
  private readonly messagesLink = [RouteSegment.Root, RouteSegment.Messages];
  private readonly paymentsLink = [RouteSegment.Root, RouteSegment.Payments];
  private readonly reporting = [RouteSegment.Root, RouteSegment.Reporting];
  private readonly insights = [RouteSegment.Root, RouteSegment.Insights];
  private readonly taxFormPrePopuation = [RouteSegment.Root, RouteSegment.TaxFormPrePopulation];
  private readonly taxRiskReporting = [RouteSegment.Root, CustodianRouteSegment.TaxRiskReporting];
  private readonly queryManagement = [RouteSegment.Root, CustodianRouteSegment.QueryManagement];
  private readonly contactUs = [RouteSegment.Root, RouteSegment.ContactUs];
  private readonly addOnTaxServices = [RouteSegment.Root, CustodianRouteSegment.AddOnTaxServices];
  private readonly corporateActionReclaims = [CustodianRouteSegment.AddOnTaxServices, AddOnTaxServicesRouteSegment.CorporateActionReclaims];
  private readonly substantialHoldingsReclaims = [
    CustodianRouteSegment.AddOnTaxServices,
    AddOnTaxServicesRouteSegment.SubstantialHoldingsReclaims,
  ];
  private readonly crossBorderReclaims = [CustodianRouteSegment.AddOnTaxServices, AddOnTaxServicesRouteSegment.CrossBorderReclaims];
  private readonly nonStandardMarketReclaims = [
    CustodianRouteSegment.AddOnTaxServices,
    AddOnTaxServicesRouteSegment.NonStandardMarketReclaims,
  ];
  private readonly migrationSupport = [CustodianRouteSegment.AddOnTaxServices, AddOnTaxServicesRouteSegment.MigrationSupport];
  private readonly healthChecksOnBeneficialOwners = [TaxRiskReportingRouteSegment.HealthChecksOnBeneficialOwners];
  private readonly healthChecksOnTaxRateTables = [TaxRiskReportingRouteSegment.HealthChecksOnTaxRateTables];
  private readonly taxReclaimAccrualChecks = [TaxRiskReportingRouteSegment.TaxReclaimAccrualChecks];
  public readonly DesignFeature = DesignFeature;
  public readonly isNewLogo = this.config.designFeatures.includes(DesignFeature.NewLogo);
  public readonly isNewLoginIcon = this.config.designFeatures.includes(DesignFeature.NewIcons);
  public readonly isCurrentUserCustodian$ = this.store$.pipe(select(isCurrentUserCustodian));

  public readonly myAccountLink = [RouteSegment.Root, RouteSegment.MyAccount];

  public readonly accountCreationInProgress$: Observable<boolean> =
    environment.debug && this.config.skipAccountCompletenessCheck
      ? of(false)
      : combineLatest([
          this.store$.pipe(select(fromProfile.selectUserProfileCompleteness)),
          this.store$.pipe(select(fromProfile.selectUserRestrictedToAccountSetup)),
        ]).pipe(
          map(
            ([accountCompleteness, restrictedToAccountSetup]) =>
              restrictedToAccountSetup || accountCompleteness === AccountCompleteness.INCOMPLETE
          )
        );

  public readonly user$ = this.store$.pipe(select(fromProfile.selectUserProfile));
  public readonly userRole$ = this.store$.pipe(select(fromProfile.selectUserRole));
  public readonly rootLink$ = this.userRole$.pipe(map((role) => roleLandingMap.get(role) || []));
  public readonly items$ = combineLatest([this.user$, this.userRole$]).pipe(
    map(([user, role]) => (isNil(role) ? [] : (this.getMenuItems(role, user.messaging, user.report_type) ?? [])))
  );
  public readonly hasAccountSettings$ = this.userRole$.pipe(map((role) => role !== UserRole.INVESTOR));

  @ViewChild('profile') public profile: ElementRef<HTMLElement>;
  public profileVisible$: Observable<boolean>;

  @ViewChild('profileStaticIcon') public profileStaticIcon: ElementRef<HTMLElement>;
  public iconStatic$: Observable<boolean>;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly authService: AuthService,
    private readonly store$: Store<fromProfile.State>,
    private readonly messageService: MessageService,
    private readonly gtmService: MenuItemGtmService
  ) {}

  public ngAfterViewInit(): void {
    // Keep the profile menu visible for a short time after the mouse left it.
    // This is to prevent menu to disappear while the user moves the mouse
    // between the icon and the menu itself.
    this.profileVisible$ = merge(
      fromEvent(this.profile.nativeElement, 'mouseenter').pipe(mapTo(true)),
      fromEvent(this.profile.nativeElement, 'mouseleave').pipe(mapTo(false))
    ).pipe(switchMap((visible) => of(visible).pipe(delay(visible ? 0 : 150))));
  }

  public onLogoutClick(): void {
    this.authService.logout();
  }

  private getUnreadMessageNumberBadge$(): Observable<NavigationItemBadge | undefined> {
    return this.messageService.getCachedMessageThreads$().pipe(
      map((messageThreads) => {
        const hasUnreadMessages = messageThreads.some((messageThread) => messageThread.unreadMessages > 0);
        return hasUnreadMessages ? NavigationItemBadge.Warning : undefined;
      }),
      shareReplay(1)
    );
  }

  private createRouterLinkMenuItem(name: string, link: string[], badge$?: Observable<NavigationItemBadge | undefined>): MenuItem {
    return { type: MenuItemType.RouterLink, name, link, badge$ };
  }

  private createRouterLinkMenuItemWithSubMenuItems(
    name: string,
    link: string[],
    subMenuItems: MenuItem[],
    badge$?: Observable<NavigationItemBadge | undefined>
  ): MenuItem {
    return { type: MenuItemType.RouterLink, name, link, badge$, subMenuItems };
  }

  private createHrefMenuItem(name: string, link: string, openInNewTab = false): MenuItem {
    return { type: MenuItemType.Href, name, link, openInNewTab };
  }

  private getMenuItems(role: UserRole, messaging: boolean, report_type: ReportType) {
    const menuItemsRoleMap: ReadonlyMap<UserRole, MenuItem[]> = new Map<UserRole, MenuItem[]>([
      [
        UserRole.WEALTH_MANAGER,
        [
          ...(this.config.restrictedRoutes.includes(RouteSegment.Dashboard)
            ? []
            : [this.createRouterLinkMenuItem('HEADER.DASHBOARD', this.dashboardLink)]),
          this.createRouterLinkMenuItem('HEADER.MAKE_CLAIM', this.clientManagementLink),
          this.createRouterLinkMenuItem('HEADER.REFUNDS', this.paymentsLink),
          ...(this.config.messagingDisabledRoles.includes(UserRole.WEALTH_MANAGER) || !messaging
            ? []
            : [this.createRouterLinkMenuItem('HEADER.MESSAGES', this.messagesLink, this.getUnreadMessageNumberBadge$())]),
          ...(this.config.restrictedUI.includes(Feature.ReportingPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.REPORTING', this.reporting)]),
          ...(this.config.restrictedUI.includes(Feature.InsightsPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.INSIGHTS', this.insights)]),
          ...(this.config.restrictedUI.includes(Feature.ReportingLink) || report_type !== ReportType.REPORTING_PORTAL
            ? []
            : [this.createHrefMenuItem('HEADER.REPORTING', this.config.reportingUrl, true)]),
        ],
      ],
      [
        UserRole.FUND_MANAGER,
        [
          ...(this.config.restrictedRoutes.includes(RouteSegment.Dashboard)
            ? []
            : [this.createRouterLinkMenuItem('HEADER.DASHBOARD', this.dashboardLink)]),
          this.createRouterLinkMenuItem('HEADER.MAKE_CLAIM', this.fundManagementLink),
          this.createRouterLinkMenuItem('HEADER.REFUNDS', this.paymentsLink),
          ...(this.config.messagingDisabledRoles.includes(UserRole.FUND_MANAGER) || !messaging
            ? []
            : [this.createRouterLinkMenuItem('HEADER.MESSAGES', this.messagesLink, this.getUnreadMessageNumberBadge$())]),
          ...(this.config.restrictedUI.includes(Feature.ReportingPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.REPORTING', this.reporting)]),
          ...(this.config.restrictedUI.includes(Feature.InsightsPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.INSIGHTS', this.insights)]),
          ...(this.config.restrictedUI.includes(Feature.ReportingLink) || report_type !== ReportType.REPORTING_PORTAL
            ? []
            : [this.createHrefMenuItem('HEADER.REPORTING', this.config.reportingUrl, true)]),
        ],
      ],
      [
        UserRole.BENEFICIAL_OWNER,
        [
          ...(this.config.restrictedRoutes.includes(RouteSegment.Dashboard)
            ? []
            : [this.createRouterLinkMenuItem('HEADER.DASHBOARD', this.dashboardLink)]),
          this.createRouterLinkMenuItem('HEADER.MAKE_CLAIM', this.yourTasksLink),
          ...(this.config.messagingDisabledRoles.includes(UserRole.BENEFICIAL_OWNER) || !messaging
            ? []
            : [this.createRouterLinkMenuItem('HEADER.MESSAGES', this.messagesLink, this.getUnreadMessageNumberBadge$())]),
          ...(true || this.config.restrictedUI.includes(Feature.ReportingPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.REPORTING', this.reporting)]),
          ...(this.config.restrictedUI.includes(Feature.InsightsPage) || report_type !== ReportType.POWERBI_REPORTING
            ? []
            : [this.createRouterLinkMenuItem('HEADER.INSIGHTS', this.insights)]),
          ...(true || this.config.restrictedUI.includes(Feature.ReportingLink) || report_type !== ReportType.REPORTING_PORTAL
            ? []
            : [this.createHrefMenuItem('HEADER.REPORTING', this.config.reportingUrl, true)]),
        ],
      ],
      [
        UserRole.INVESTOR,
        [
          this.createRouterLinkMenuItem('HEADER.DOCUMENTS', this.yourTasksLink),
          ...(this.config.messagingDisabledRoles.includes(UserRole.INVESTOR) || !messaging
            ? []
            : [this.createRouterLinkMenuItem('HEADER.MESSAGES', this.messagesLink, this.getUnreadMessageNumberBadge$())]),
        ],
      ],
      [
        UserRole.CUSTODIAN,
        [
          this.createRouterLinkMenuItem('HEADER.TAX_FORM_PRE_POPULATION', this.taxFormPrePopuation),
          this.createRouterLinkMenuItem('HEADER.QUERY_MANAGEMENT', this.queryManagement),
          this.createRouterLinkMenuItemWithSubMenuItems('HEADER.TAX_RISK_REPORTING.TAX_RISK_REPORTING_HEADER', this.taxRiskReporting, [
            this.createRouterLinkMenuItem(
              'HEADER.TAX_RISK_REPORTING.SUB_HEADER.HEALTH_CHECKS_ON_BENEFICIAL_OWNERS',
              this.healthChecksOnBeneficialOwners
            ),
            this.createRouterLinkMenuItem(
              'HEADER.TAX_RISK_REPORTING.SUB_HEADER.HEALTH_CHECKS_ON_TAX_RATE_TABLES',
              this.healthChecksOnTaxRateTables
            ),
            this.createRouterLinkMenuItem('HEADER.TAX_RISK_REPORTING.SUB_HEADER.TAX_RECLAIM_ACCRUAL_CHECKS', this.taxReclaimAccrualChecks),
          ]),
          this.createRouterLinkMenuItemWithSubMenuItems('HEADER.ADD_ON_TAX_SERVICES.ADD_ON_TAX_SERVICES_HEADER', this.addOnTaxServices, [
            this.createRouterLinkMenuItem('HEADER.ADD_ON_TAX_SERVICES.SUB_HEADER.CORPORATE_ACTION_RECLAIMS', this.corporateActionReclaims),
            this.createRouterLinkMenuItem(
              'HEADER.ADD_ON_TAX_SERVICES.SUB_HEADER.SUBSTANTIAL_HOLDING_RECLAIMS',
              this.substantialHoldingsReclaims
            ),
            this.createRouterLinkMenuItem('HEADER.ADD_ON_TAX_SERVICES.SUB_HEADER.CROSS_BORDER_RECLAIMS', this.crossBorderReclaims),
            this.createRouterLinkMenuItem(
              'HEADER.ADD_ON_TAX_SERVICES.SUB_HEADER.NON_STANDARD_MARKET_RECLAIMS',
              this.nonStandardMarketReclaims
            ),
            this.createRouterLinkMenuItem('HEADER.ADD_ON_TAX_SERVICES.SUB_HEADER.MIGRATION_SUPPORT', this.migrationSupport),
          ]),
          this.createRouterLinkMenuItem('HEADER.CONTACT_US', this.contactUs),
        ],
      ],
    ]);
    return menuItemsRoleMap.get(role);
  }
  public onLogoClick(): void {
    this.gtmService.pushMenuItemClickedTag('LOGO');
  }
}
